import { compose } from "recompose";
import { graphql } from "react-apollo";
import withErrorFallback from "theme/modules/PageError/withErrorFallback";

const EnhanceTopNotice = ({ TopNoticeQuery }) =>
  compose(
    graphql(TopNoticeQuery, {
      name: "topNoticeData",
      options: ({ mobileView }) => ({
        variables: {
          isMobile: mobileView,
        },
      }),
      props: ({ topNoticeData }) => {
        const topNotice = topNoticeData?.topNotice;
        return {
          loading: topNoticeData.loading,
          topNotice: topNotice?.noticeCmsBlock,
          css: topNotice?.css,
        };
      },
    }),
    withErrorFallback({
      hasError: (props) => props.error,
    })
  );

export default EnhanceTopNotice;
