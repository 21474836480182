import { compose } from "recompose";
import { graphql } from "react-apollo";

const EnhanceApmSticker = ({ KlubApmConfigQuery }) =>
    compose(
        graphql(KlubApmConfigQuery, {
                props: ({ data }) => {
                    return {
                        loading: data.loading,
                        isActive: !data.loading && !data.error,
                        config: (!data.loading && data.klubApmConfig) || {},
                    }
                },
            }
        )
    );

export default EnhanceApmSticker;