import React from "react";
import PropTypes from "prop-types";

const ExplainedCallToAction = ({ title, button, children }) => {
  return (
    <div className="explained-call-to-action">
      <div className="explained-call-to-action__title">
        {title}
      </div>
      <div className="explained-call-to-action__content">{children}</div>
      {button && (
        <div className="explained-call-to-action__call-to-action">
          {React.Children.only(button)}
        </div>
      )}
    </div>
  );
};

ExplainedCallToAction.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  button: PropTypes.node,
};

export default ExplainedCallToAction;
