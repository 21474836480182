import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconButton } from "theme/components/atoms/Button";

const messages = defineMessages({
  loading: {
    id: "layouts.Header.Navigation.loading",
    defaultMessage: "Loading user information...",
  },
  myAccount: {
    id: "layouts.Header.Navigation.account",
      defaultMessage: "My Account",
  },
});

const AccountIcon = ({ loading }) => {
  const intl = useIntl();

  return (
    <IconButton
      icon="user"
      title={loading
        ? intl.formatMessage(messages.loading)
        : intl.formatMessage(messages.myAccount)}
      dangerouslyIgnoreOnClick
    />
  );
};

export default AccountIcon;
