import React from "react";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  tooManyItems: {
    id: "modules.Cart.CartItem.CartItemStatus.CartItemErrors.tooManyItems",
    defaultMessage:
      "* The requested quantity is not available.",
  },
  outOfStock: {
    id: "modules.Cart.CartItem.CartItemStatus.CartItemErrors.outOfStock",
    defaultMessage:
      "This item is no longer in stock. Please remove it from your cart before proceeding.",
  },
  notAvailableInWebsite: {
    id:
      "modules.Cart.CartItem.CartItemStatus.CartItemErrors.notAvailableInWebsite",
    defaultMessage:
      "This item is no longer available. Please remove it from your cart before proceeding.",
  },
  other: {
    id: "modules.Cart.CartItem.CartItemStatus.CartItemErrors.other",
    defaultMessage:
      "This item is invalid. Please remove it from your cart before proceeding.",
  },
});

export const hasError = (item) => item && item.errors && item.errors.length > 0;

const CartItemErrors = ({ intl, item }) => {
  if (!hasError(item)) {
    return null;
  }

  return (
    <React.Fragment>
      {item.errors
        .map((error) => {
          if (error.id === "outOfStock") {
            if (
              item.product.stock.isInStock === true &&
              item.product.stock.qty > 0
            ) {
              return intl.formatMessage(messages.tooManyItems);
            } else {
              return intl.formatMessage(messages.outOfStock);
            }
          } else if (messages[error.id]) {
            return intl.formatMessage(messages[error.id]);
          } else {
            return intl.formatMessage(messages.other);
          }
        })
        .join("\n")}
    </React.Fragment>
  );
};

export default injectIntl(CartItemErrors);
