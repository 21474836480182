import React from "react"
import PropTypes from "prop-types"
import Button from "theme/components/atoms/Button"
import {useHistory} from "react-router"


const ObfuscatedLink = ({href, target, route, ...props}) => {
  const history = useHistory()

  const onClick = () => {
    if( href ) {
      const a = document.createElement("a")

      a.setAttribute('href', href)
      if( target ) a.setAttribute('target', target)

      a.click()
    } else {
      history.push(route)
    }
  }

  return <Button
    {...props}
    onClick={onClick}
  >{props.children}</Button>
}

ObfuscatedLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  route: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ObfuscatedLink.defaultProps = {
  target: null,
  route: null,
}

export default ObfuscatedLink