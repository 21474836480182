import React from "react";
import { useHistory } from "react-router";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { H2 } from "theme/components/atoms/Typography/Heading";
import ExplainedCallToAction from "theme/components/organisms/ExplainedCallToAction";
import Icon from "theme/components/atoms/Icon";
import Button from "theme/components/atoms/Button";

const messages = defineMessages({
  account: {
    id: "modules.RegisterForm.account",
    defaultMessage: "My account",
  },
  addresses: {
    id: "layouts.Header.Navigation.AccountNavigation.AccountSubNavigation.addresses",
    defaultMessage: "My addresses",
  },
  submit: {
    id: "modules.User.LoginForm.submit",
    defaultMessage: "Sign in",
  },
  forgotPassword: {
    id: "modules.User.LoginForm.forgotPassword",
    defaultMessage: "Forgot your password?",
  },
  info: {
    id: "layouts.Header.Navigation.AccountNavigation.AccountSubNavigation.info",
    defaultMessage: "My info",
  },
  orders: {
    id: "layouts.Header.Navigation.AccountNavigation.AccountSubNavigation.orders",
    defaultMessage: "My orders",
  },
});

const ThreeStars = () => {
  return (
    <div className="account-popin__stars">
      <Icon icon="star" title="star" size="mini" />
      <Icon icon="star" title="star" size="mini" />
      <Icon icon="star" title="star" size="mini" />
    </div>
  );
};

const AccountLoginPopin = (props) => {
  const history = useHistory();
  const { formatMessage } = props.intl;

  return props.user?.id ? (
    <div className="account-popin desktop-only">
      <div className="account-popin__links">
        <Button
          appearance="link"
          onClick={() => {
            history.push("/user/orders");
          }}
        >
          {formatMessage(messages.orders)}
        </Button>
        <Button
          appearance="link"
          onClick={() => {
            history.push("/user/information");
          }}
        >
          {formatMessage(messages.info)}
        </Button>
        <Button
          appearance="link"
          onClick={() => {
            history.push("/user/address-book");
          }}
        >
          {formatMessage(messages.addresses)}
        </Button>
      </div>
      <ThreeStars />
      <div className="account-popin__register account">
        <ExplainedCallToAction
          title={
            <H2>
              <FormattedMessage
                id="components.organisms.Register.RegisterForm.title"
                defaultMessage="New here ?"
              />
            </H2>
          }
        >
          <Button
            appearance="primary"
            onClick={() => {
              history.push("/user");
            }}
          >
            <FormattedMessage
              id="modules.RegisterForm.account"
              defaultMessage="My account"
            />
          </Button>
        </ExplainedCallToAction>
      </div>
    </div>
  ) : (
    <div className="account-popin desktop-only">
      <div className="account-popin__login">
        <ExplainedCallToAction
          title={
            <span>
              <FormattedMessage
                id="components.organisms.Login.LoginForm.title"
                defaultMessage="Already registered ?"
              />
            </span>
          }
        >
          <Button
            appearance="primary"
            onClick={() => {
              history.push("/login");
            }}
          >
            <FormattedMessage
              id="modules.User.AccountLink.signin"
              defaultMessage="Sign in"
            />
          </Button>
          <div className="forgotten-password">
            {" "}
            <Button
              className="password-link"
              onClick={() => {
                history.push("/account/password-request");
              }}
            >
              {formatMessage(messages.forgotPassword)}
            </Button>
          </div>
        </ExplainedCallToAction>
      </div>
      <div className="account-popin__links">
        <Button
          appearance="link"
          onClick={() => {
            history.push("/login");
          }}
        >
          {formatMessage(messages.orders)}
        </Button>
        <Button
          appearance="link"
          onClick={() => {
            history.push("/login");
          }}
        >
          {formatMessage(messages.info)}
        </Button>
        <Button
          appearance="link"
          onClick={() => {
            history.push("/login");
          }}
        >
          {formatMessage(messages.addresses)}
        </Button>
      </div>
      <ThreeStars />
      <div className="account-popin__register">
        <ExplainedCallToAction
          title={
            <span>
              <FormattedMessage
                id="components.organisms.Register.RegisterForm.title"
                defaultMessage="New here ?"
              />
            </span>
          }
        >
          <Button
            appearance="primary"
            onClick={() => {
              history.push("/login");
            }}
          >
            <FormattedMessage
              id="modules.RegisterForm.create"
              defaultMessage="Create my account"
            />
          </Button>
        </ExplainedCallToAction>
      </div>
    </div>
  );
};

export default injectIntl(AccountLoginPopin);
