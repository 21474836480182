import React from "react";
import PropTypes from "prop-types";
import H4 from "theme/components/atoms/Typography/Heading/H3";

const ResultsBlock = ({ title, sort, children }) => {
  return (
    <div>
      <div className="results-block__info">
        {sort && <div className="results-block__sort">{sort}</div>}
        {title && (
          <div className="results-block__title">
            <H4>{title}</H4>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

ResultsBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
};

export default ResultsBlock;
