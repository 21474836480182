import React, { Fragment } from "react";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";
import UpdateCartItemQtyMutation from "theme/modules/Cart/CartItem/CartItemQuantityForm/UpdateCartItemQtyMutation.gql";
import RemoveCartItemMutation from "theme/modules/Cart/CartItem/CartItemRemoveForm/RemoveCartItemMutation.gql";
import SelectedOptions from "theme/modules/ProductView/SelectedOptions";
import CartItemQuantityForm from "../CartItemQuantityForm";
import EnhanceCartItemQuantityForm from "../CartItemQuantityForm/EnhanceCartItemQuantityForm";
import EnhanceRemoveItemCart from "../CartItemRemoveForm/EnhanceRemoveItemCart";
import CartItemStatus from "../CartItemStatus";
import { defineMessages, useIntl } from "react-intl";
import ProductPrice from "theme/components/atoms/Typography/Price/ProductPrice";

const messages = defineMessages({
  Size: {
    id: "modules.Cart.MiniCart.MiniCartContent.MiniCartItem.options.Size",
    defaultMessage: "size",
  },
});

const MiniCartItem = ({
  item,
  onRefreshQuantity,
  refreshQuantityStatus,
  removeItemStatus,
}) => {
  const intl = useIntl();

  const productImage = item.product?.image_thumbnail || item.product?.imageUrl;
  const productColor = item.product?.product_color || "";
  const productName = `${item.name}  ${productColor}`;

  const translatedOptions = item.options.map((option) => {
    return {
      ...option,
      label: messages[option.label]
        ? intl.formatMessage(messages[option.label])
        : option.label,
    };
  });

  return (
    <div className="minicart__item">
      <div className="minicart__item__image">
        <Image src={productImage} alt={productName} format="cartPreview" />
      </div>
      <div className="minicart__item__content">
        <div className="minicart__item__name">
          <TrackingLink
            to={item.product.path}
            eventName="Product Clicked"
            eventPayload={{
              sku: item.sku,
              name: productName,
            }}
          >
            {productName}
          </TrackingLink>
        </div>
        <CartItemStatus
          refreshQuantityStatus={refreshQuantityStatus}
          removeItemStatus={removeItemStatus}
        />
        <div className="minicart__item__details">
          <div className="minicart__item__options">
            <div className="minicart__item__size">
              <Fragment>
                {translatedOptions?.length || item.bundleOptions?.length ? (
                  <SelectedOptions
                    options={translatedOptions}
                    bundleOptions={item.bundleOptions}
                  />
                ) : null}
              </Fragment>
            </div>
            <div className="minicart__item__quantity">
              <CartItemQuantityForm
                quantity={item.qty}
                item_id={item.item_id}
                disabled={removeItemStatus.commandPending}
                onRefreshQuantity={onRefreshQuantity}
                refreshQuantityPending={refreshQuantityStatus.commandPending}
                appearance={"small"}
              />
            </div>
          </div>
          <div className="minicart__item__price">
            <ProductPrice
              prices={item.product.prices}
              item={item}
              forceFinalPrice={true}
              showPriceLabel={true}
              withSeo={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  EnhanceRemoveItemCart({ RemoveCartItemMutation }),
  EnhanceCartItemQuantityForm({ UpdateCartItemQtyMutation })
)(MiniCartItem);
