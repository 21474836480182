import { compose, withHandlers, withProps } from "recompose";
import withDebouncedCallback from "theme/components/helpers/withDebouncedCallback";
import CartQtyQuery from "theme/layouts/Header/CartQtyQuery.gql";
import CartQuery from "theme/pages/Cart/CartQuery.gql";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";

const EnhanceCartItemQuantityForm = ({ UpdateCartItemQtyMutation }) =>
  compose(
    makeCommandDispatcher({
      handleChangeItemQuantity: (props) => ({ newQuantity, itemId }) => {
        return {
          options: {
            mutation: UpdateCartItemQtyMutation,
            variables: {
              item_id: itemId,
              qty: newQuantity,
            },
            update: (store, { data: changeItemQtyInCart }) => {
              const response = changeItemQtyInCart.changeItemQtyInCart;
              if (response.success) {
                const cart = response.cart;
                store.writeQuery({
                  query: CartQtyQuery,
                  data: {
                    cart: {
                      id: cart.id,
                      items_qty: cart.items_qty,
                      __typename: cart.__typename,
                    },
                  },
                });
                store.writeQuery({
                  query: CartQuery,
                  data: { cart: cart },
                });
              }
            },
          },
          messages: {
            success: null,
            error: null,
          },
        };
      },
    }),
    withHandlers({
      onRefreshQuantity: (props) => (data) => {
        if (data.quantity && data.quantity !== props.item.qty) {
          props.handleChangeItemQuantity({
            itemId: props.item.item_id,
            newQuantity: parseInt(data.quantity, 10),
          });
        }
      },
    }),
    withDebouncedCallback("onRefreshQuantity", 1000),
    withProps((props) => {
      const message =
        props.hasMessage &&
        !props.success &&
        typeof props.commandResponse !== "string" &&
        props.commandResponse.errorMessage
          ? props.commandResponse.errorMessage
          : props.message;

      return {
        refreshQuantityStatus: {
          commandPending: props.commandPending,
          hasMessage: props.hasMessage,
          message: message,
          success: props.success,
        },
      };
    })
  );

export default EnhanceCartItemQuantityForm;
