import React, { Fragment } from "react";
import { InlineAlert } from "theme/components/molecules/Alert";
import CartItemErrors, { hasError } from "./CartItemErrors";

const CommandStatus = ({ status }) => {
  if (!status.hasMessage) {
    return null;
  }

  if (status.success) {
    return null;
  }

  return (
    <div className="cart-item-status">
      <InlineAlert appearance={status.success ? null : "error"}>
        * {status.message}
      </InlineAlert>
    </div>
  );
};

const CartItemStatus = (props) => {
  return (
    <Fragment>
      <CommandStatus status={props.refreshQuantityStatus} />
      <CommandStatus status={props.removeItemStatus} />

      {hasError(props.item) && (
        <div className="cart-item-status">
          <InlineAlert appearance="error">
            <CartItemErrors item={props.item} />
          </InlineAlert>
        </div>
      )}
    </Fragment>
  );
};

export default CartItemStatus;
