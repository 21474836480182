import React, { useEffect } from "react";
import EnhanceSearchBar from "./EnhanceSearchBar";
import Autocomplete from "theme/components/organisms/Autocomplete/Autocomplete";
import SearchBarQuery from "./SearchBarQuery.gql";
import WysiwygSearchBar from "./WysiwygSearchBar.gql";
import SearchBarResults from "./SearchBarResults";
import useSearchBar from "./useSearchBar";
import SearchBarForm from "./SearchBarForm";
import classNames from "classnames";
import useSearchList from "./useSearchList";
import CmsBlock from "theme/modules/CmsBlock";
import SearchBarResultsLoading from "theme/modules/Search/SearchBar/SearchBarResults/SearchBarResultsEmptyLoading";

const hasResults = ({ products }) => products.length > 0;

const SearchBar = ({
  search,
  setSearch,
  data,
  loading,
  error,
  closeSearch,
  algoliaConfig,
  setSearchProducts,
  me,
}) => {
  const {
    contentRef,
    searchUrl,
    searchTarget,
    onSubmit,
    onChange,
    showAutocompleteResults,
    isOpen,
    setIsOpen,
    selected,
    onSelect,
    onLoadMore,
  } = useSearchBar({ search, setSearch, data, algoliaConfig });
  const { products } = useSearchList({ search, data });
  const isEmpty = !products?.products || !hasResults(products);

  useEffect(() => {
    data?.searchSuggestions?.products.length > 0 &&
      setSearch((prevState, props) => ({ ...prevState, canUpdate: false }));
  }, [setSearch, data?.searchSuggestions?.products]);

  const algoliaNoResult =
    !me.loading &&
    me.cmsBlock.find((el) => el.identifier === "algolia-no-result");

  const algoliaBeforeResult =
    !me.loading &&
    me.cmsBlock.find((el) => el.identifier === "algolia-before-result");

  const algoliaBeforeResultB =
    !me.loading &&
    me.cmsBlock.find((el) => el.identifier === "algolia-before-result-b");

  return (
    <div className="search-box" ref={contentRef}>
      <div className="search-box__form">
        <SearchBarForm
          search={search}
          searchTarget={searchTarget}
          data={data}
          selected={selected}
          isExpanded={showAutocompleteResults}
          onSubmit={onSubmit}
          onChange={onChange}
          closeSearch={closeSearch}
          setSearch={setSearch}
        />
      </div>
      <div
        className={classNames("search-box__replaced-content", {
          "search-box__replaced-content__hide": !isEmpty || !error,
          "search-box__replaced-content__show": isEmpty || error,
        })}
      >
        {loading && <SearchBarResultsLoading />}
        {data?.searchSuggestions?.products.length === 0 &&
        search?.search !== "" &&
        algoliaNoResult ? (
          <>
            <CmsBlock cmsBlock={algoliaNoResult} />
          </>
        ) : (
          <>
            {algoliaBeforeResult && <CmsBlock cmsBlock={algoliaBeforeResult} />}
            {algoliaBeforeResultB && (
              <CmsBlock cmsBlock={algoliaBeforeResultB} />
            )}
          </>
        )}
      </div>
      {isOpen && (
        <Autocomplete onClose={() => setIsOpen(false)}>
          <SearchBarResults
            loading={loading ?? false}
            error={error}
            search={search}
            searchUrl={searchUrl}
            results={products}
            selected={selected}
            onChange={onChange}
            setSearch={setSearch}
            onLoadMore={onLoadMore}
            onSelect={onSelect}
            algoliaSortingIndices={algoliaConfig.sortingIndices}
            hitsPerPage={algoliaConfig.hitsPerPage}
            setSearchProducts={setSearchProducts}
          />
        </Autocomplete>
      )}
    </div>
  );
};

export default EnhanceSearchBar({
  SearchQuery: SearchBarQuery,
  WysiwygSearchBar,
})(SearchBar);
