import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import useKeyDownEvent from "theme/components/helpers/a11y/useKeyDownEvent";
import useBodyFixed from "theme/components/organisms/Modal/useBodyFixed";
import { CSSTransition } from "react-transition-group";

const messages = defineMessages({
  close: {
    id: "layouts.Header.SubNavigation.close",
    defaultMessage: "Close",
  },
});

const SubSearchNavigation = ({
  appearance = "default",
  children,
  onClose,
  onEnter,
}) => {
  const intl = useIntl();

  const contentRef = useRef();

  const [entered, setEntered] = useState(false);
  useEffect(() => {
    setEntered(true);
  }, []);

  const onCloseHandler = useCallback(
    (event) => {
      event.preventDefault();
      setTimeout(() => {
        onClose();
      }, 300);
    },
    [onClose]
  );

  useKeyDownEvent(
    contentRef,
    useCallback(
      (event) => {
        // if escape key is pressed within contentRef
        if (event.keyCode === 27) {
          onCloseHandler(event);
        }
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      },
      [onCloseHandler]
    )
  );

  useBodyFixed(entered && appearance === "aside");

  useLayoutEffect(() => {
    if (appearance === "aside" && contentRef.current) {
      const box = contentRef.current.getBoundingClientRect();
      contentRef.current.style.height = `${Math.ceil(
        window.innerHeight - box.top
      )}px`;
    }
  }, [appearance]);

  return (
    <CSSTransition
      in={true}
      timeout={500}
      classNames="modal-transition"
      unmountOnExit
    >
      <div>
        <div
          className={classNames("navigation__sub__search", {
            "navigation__sub__search--opened": entered,
            [`navigation__sub__search--${appearance}`]: appearance,
          })}
        >
          <div
            tabIndex="-1"
            ref={contentRef}
            className="navigation__sub__search__content"
            onMouseEnter={appearance !== "menu" ? onEnter : null}
          >
            <button className="sr-only sr-focusable" onClick={onCloseHandler}>
              {intl.formatMessage(messages.close)}
            </button>
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SubSearchNavigation;
