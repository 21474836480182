import React from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";

const OptionValue = ({ option }) => {
  const intl = useIntl();

  const optionsLabels = defineMessages({
    Size: {
      id: "modules.User.Order.OrderRecap.sizeLabel",
      defaultMessage: "Size",
    },
    Longueur: {
      id: "modules.User.Order.OrderRecap.lengthLabel",
      defaultMessage: "Length",
    },
    kp_inseam: {
      id: "modules.User.Order.OrderRecap.lengthLabel",
      defaultMessage: "Length",
    },
  })
   
  return (
    <div>
    <FormattedMessage
      id="modules.ProductView.SelectedOptions.option"
      defaultMessage="{label} <strong>{value}</strong> "
      values={{
        label: optionsLabels?.[option.label]
        ? intl.formatMessage(optionsLabels[option.label])
        : option.label,
        value: option.value,
        strong: (text) => <strong>{text}</strong>,
      }}
    />
  </div>
  );
 
};

export default OptionValue;