import React, { useMemo, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import Link from "theme/components/atoms/Typography/Link";
import KlubApmConfigQuery from "./KlubApmConfigQuery.gql";
import EnhanceApmSticker from "./EnhanceApmSticker";

const ApmSticker = ({
  customerEmail,
  customerClientId,
  customerFirstname,
  customerLastname,
  config
}) => {
    const [scriptHasLoaded, setScriptHasLoaded] = useState(false);

    // State script callback.
    const callbackSetScriptHasLoaded = useCallback(() => {
      setScriptHasLoaded(true);
    }, [setScriptHasLoaded]);

    // Script loading & callback.
    const loadApmApiScript = useCallback(() => {
      const existingScript = document.getElementById('apmApiScript');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = '/fr_fr/js/d-engage/d-engage.js';

        script.id = 'apmApiScript';
        document.body.appendChild(script);

        script.onload = () => {
          callbackSetScriptHasLoaded();
        };
      }
    }, [callbackSetScriptHasLoaded]);

    // Boolean if config is loaded with api url link.
    const appsmilesConfLoaded = useMemo (
      () => !!config?.apiBaseUrl,
      [config]
    );

  // Setup global variable for apm when config is ready and set.
    window.apmConfig = useMemo (
      () => !!config?.apiBaseUrl && config,
      [config]
    );

  // Load JS script onto page.
  useEffect(() => {
    if (!appsmilesConfLoaded) return;
    loadApmApiScript();
  }, [appsmilesConfLoaded, loadApmApiScript]);

    // Enable Appsmiles api.
    useEffect(() => {
      if (scriptHasLoaded && appsmilesConfLoaded) {
        window.ApmApi.userClientConnect(
          customerEmail,
          customerClientId,
          customerFirstname,
          customerLastname,
          JSON,
          0
        );
      }
    }, [
      customerClientId,
      customerEmail,
      customerFirstname,
      customerLastname,
      appsmilesConfLoaded,
      scriptHasLoaded
    ]);

    return (
      <>
        {
          appsmilesConfLoaded &&
          <Helmet>
            <meta name="referrer" content="strict-origin-when-cross-origin"/>
            <link rel="stylesheet" type="text/css" href="/fr_fr/css/d-engage/style.css" media="screen"/>
          </Helmet>
        }
        {
        config?.apiBaseUrl &&
          <div className="top-klub-container">
            <Link
              to="/user/leklub/benefits"
              modifiers={{
                'top-klub-container--link' : true,
              }}
            >
              &nbsp;
            </Link>
            <div id="d-engage" />
          </div>
        }
      </>
    )
}

// Use Enhancer to load Apm config data.
const EnhancedApmSticker = EnhanceApmSticker({
  KlubApmConfigQuery
})(ApmSticker)

ApmSticker.propTypes = {
    customerEmail: PropTypes.string.isRequired,
    customerClientId: PropTypes.string.isRequired,
    customerFirstname: PropTypes.string.isRequired,
    customerLastname: PropTypes.string.isRequired,
}

export default EnhancedApmSticker;