import PropTypes from "prop-types";
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useLocation } from "react-router";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { compose } from "recompose";
import Logo from "theme/components/atoms/Logo";
import Link from "theme/components/atoms/Typography/Link";
import { withErrorBoundary } from "theme/components/helpers/ErrorBoundary/ErrorBoundary";
import { withShop } from "web/core/shop/ShopContext";
import withIsFeatureActive from "web/core/UNSAFE_featureFlags/withIsFeatureActive";
import AlgoliaConfig from "./AlgoliaConfig.gql";
import CartQtyQuery from "./CartQtyQuery.gql";
import CssOverrideQuery from "./CssOverrideQuery.gql";
import StoreConfigurationQuery from "./StoreConfigurationQuery.gql";
import EnhanceHeader from "./EnhanceHeader";
import HeaderQuery from "./HeaderQuery.gql";
import HeaderUserQuery from "./HeaderUserQuery.gql";
import MenuMainCategories from "./Navigation/Menu/MenuMainCategories";
import MenuNavigationMobile from "./Navigation/Menu/MenuNavigationMobile";
import Navigation from "./Navigation/Navigation";
import SubNavigation from "./Navigation/SubNavigation";
import SimpleHeader from "./SimpleHeader";
import useSubNavigation from "./useSubNavigation";
import WishlistHeaderQuery from "./WishlistHeaderQuery.gql";
import useSubSearchNavigation from "./useSubSearchNavigation";
import { useMediaQuery } from "react-responsive";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";
import { TopNoticeWithMediaQuery } from "theme/modules/TopNotice";
import IconButton from "theme/components/atoms/Button/IconButton";

const Header = ({ isKlubEnabled, ...props }) => {
  const headerRef = useRef();
  const location = useLocation();
  const currentPathName = location.pathname;
  const [popupOpened, hasPopupOpened] = useState(false);
  const [topNoticeMobile, hasTopNoticeMobile] = useState(false);
  const [subNavRef, selectMenu, renderSubNav, selectedMenu] =
    useSubNavigation();
  const [
    subSearchRef,
    renderSearchNav,
    openSearch,
    isSearchOpened,
    setIsSearchOpened,
  ] = useSubSearchNavigation();
  const html = !process.env.SERVER && document.querySelector("html");
  const isDesktop = useMediaQuery({ maxWidth: Breakpoints.desktop });
  const isBurgerOpen = selectedMenu === "menu";

  // Add class when submenu is open.
  const headerClassName = classNames("header", {
    "header-submenu-open": selectedMenu || popupOpened || isSearchOpened,
    "top-notice-mobile-active": topNoticeMobile,
  });
  const headerBurgerClass = classNames("header__burger", {
    open: isBurgerOpen,
  });

  // Use memo to store current location for useEffect hook.
  const currentLocation = useMemo(() => {
    return currentPathName;
  }, [currentPathName]);

  const openMenu = (menu) => {
    selectMenu(menu);
    document.body.classList.add("menu-opened");
    html.classList.add("no-overflow");
  };

  const closeMenu = useCallback(() => {
    selectMenu(null);
    document.body.classList.remove("menu-opened");
    html.classList.remove("no-overflow");
  }, []);

  // Remove effect when change location.
  useEffect(() => {
    headerRef.current.removeAttribute("kha");
    closeMenu();
  }, [currentLocation, headerRef, closeMenu]);

  // Close menu when exiting tablet format.
  useEffect(() => {
    if (!isDesktop && isBurgerOpen && html.classList.contains("no-overflow")) {
      closeMenu();
    }
  }, [html, isDesktop, isBurgerOpen, closeMenu]);

  return (
    <>
      {props.cssOverride && (
        <Helmet>
          {/* The Helmet component will put the content into head tag in the html page */}
          <style type="text/css" media="screen">
            {props.cssOverride.cssOverride}
          </style>
        </Helmet>
      )}
      <TopNoticeWithMediaQuery onTopNoticeExist={hasTopNoticeMobile} />
      <header className={headerClassName} id="header" ref={headerRef}>
        <div className="header__container">
          <MediaQuery maxWidth={Breakpoints.desktop - 1}>
            <div className={headerBurgerClass}>
              <IconButton
                icon={!isBurgerOpen ? "burger" : "close"}
                onClick={() => {
                  if (isBurgerOpen) {
                    closeMenu();
                  } else {
                    openMenu("menu");
                  }
                }}
                title={"menu"}
              />
              {renderSubNav("menu")(
                <SubNavigation
                  appearance="menu"
                  onClose={() => {
                    closeMenu();
                  }}
                >
                  <MenuNavigationMobile
                    data={props.data}
                    loading={props.userData?.loading ?? true}
                    user={props.userData?.me}
                    wishlistFeature={props.wishlistFeature}
                    isKlubEnabled={isKlubEnabled}
                    klubUserData={props.userData?.klub?.user}
                    closeMenu={closeMenu}
                  />
                </SubNavigation>
              )}
            </div>
          </MediaQuery>
          <div className="header__logo">
            <Link to="/" onClick={() => closeMenu()}>
              <Logo />
            </Link>
          </div>

          <MediaQuery minWidth={Breakpoints.desktop}>
            {(isDesktop) =>
              isDesktop && (
                <div className="header__central">
                  <MenuMainCategories
                    data={props.data}
                    selectMenu={selectMenu}
                    renderSubNav={renderSubNav}
                    selectedMenu={selectedMenu}
                  />
                </div>
              )
            }
          </MediaQuery>

          <div className="header__links">
            <Navigation
              data={props.data}
              userData={props.userData}
              selectMenu={selectMenu}
              renderSubNav={renderSubNav}
              cart={props.cart.cart}
              algoliaConfig={props.algoliaConfig}
              renderSearchNav={renderSearchNav}
              openSearch={openSearch}
              isSearchOpened={isSearchOpened}
              wishlist={props.wishlist.me?.wishlist}
              setIsSearchOpened={setIsSearchOpened}
              isKlubEnabled={isKlubEnabled}
              hasPopupOpened={hasPopupOpened}
            />
          </div>
        </div>
        <div className="header__sub" ref={subNavRef} />
      </header>
      <div className="header__sub__search" ref={subSearchRef} />
    </>
  );
};

const SmartHeader = compose(
  withErrorBoundary(SimpleHeader),
  withShop(),
  EnhanceHeader({
    HeaderQuery,
    HeaderUserQuery,
    AlgoliaConfig,
    WishlistHeaderQuery,
    CssOverrideQuery,
    StoreConfigurationQuery,
  }),
  withIsFeatureActive("wishlistFeature", "wishlist")
)(Header);

SmartHeader.propTypes = {
  categories: PropTypes.array,
  user: PropTypes.object,
};

export default SmartHeader;
