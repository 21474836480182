import React, { Fragment } from "react";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import Spinner from "theme/components/atoms/Spinner";
import Link from "theme/components/atoms/Typography/Link";
import EnhanceMiniCartContent from "./EnhanceMiniCartContent";
import CartQuery from "theme/pages/Cart/CartQuery.gql";
import MiniCartItem from "theme/modules/Cart/CartItem/MiniCartItem";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import FormActions from "theme/components/molecules/Form/FormActions";

const messages = defineMessages({
  productsCount: {
    id: "modules.Cart.MiniCart.MiniCartContent.productsCount",
    defaultMessage: "({qty} {qty, plural, one {product} other {products}})",
  },
  miniCartEmpty: {
    id: "modules.Cart.MiniCart.MiniCartContent.empty",
    defaultMessage: "You don't have any product in your cart yet.",
  },
  miniCartTotalMain: {
    id: "modules.Cart.MiniCart.MiniCartContent.totalMain",
    defaultMessage: "Total",
  },
  miniCartTotalDiscount: {
    id: "modules.Cart.MiniCart.MiniCartContent.discount",
    defaultMessage: "Discount",
  },
  miniCartTotalSub: {
    id: "modules.Cart.MiniCart.MiniCartContent.totalSub",
    defaultMessage: "Excluding shipping fees",
  },
  seeCartAction: {
    id: "modules.Cart.MiniCart.MiniCartContent.seeCart",
    defaultMessage: "View my cart",
  },
  viewMore: {
    id: "modules.Cart.MiniCart.MiniCartContent.viewMore",
    defaultMessage: "And {qty} other items...",
  },
});

const MiniCartContent = ({ loading, cart, toggleMiniCart, intl }) => {
  if (loading) {
    return (
      <div className="minicart__content">
        <Spinner />
      </div>
    );
  }

  const cartItems = [...cart.items].reverse();

  return (
    <div className="minicart__content">
      {cart.items.length > 0 ? (
        <Fragment>
          {cartItems.slice(0, 3).map((item) => (
            <MiniCartItem key={item.item_id} item={item} />
          ))}
          {cart.items.length > 3 && (
            <FormActions appearance="center">
              <Link to="/cart" onClick={toggleMiniCart}>
                {intl.formatMessage(messages.viewMore, {
                  qty: cart.items.length - 3,
                  s: cart.items.length - 3 > 1 ? "s" : "",
                })}
              </Link>
            </FormActions>
          )}
        </Fragment>
      ) : (
        <div className="minicart__content__empty">
          {intl.formatMessage(messages.miniCartEmpty)}
        </div>
      )}
      {cart.items.length > 0 && (
        <div className="minicart__button">
          <FormActions appearance="full">
            <Link
              buttonAppearance="primary"
              to="/cart"
              onClick={toggleMiniCart}
            >
              {intl.formatMessage(messages.seeCartAction)}
            </Link>
          </FormActions>
        </div>
      )}
    </div>
  );
};

export default compose(
  withHideOnErrorBoundary,
  injectIntl,
  EnhanceMiniCartContent({ CartQuery })
)(MiniCartContent);
