import React from "react";
import { defineMessages, useIntl } from "react-intl";
import SearchInput from "./SearchInput";
import OneLineForm from "theme/components/organisms/Form/OneLineForm";
import IconButton from "theme/components/atoms/Button/IconButton";

const messages = defineMessages({
  search: {
    id: "modules.Search.SearchBar.SearchBarForm.search",
    defaultMessage: "Search",
  },
});

const SearchBarForm = ({
  search,
  searchTarget,
  onChange,
  setSearch,
  onFocus,
  isExpanded,
  selected,
  closeSearch,
}) => {
  const intl = useIntl();

  return (
    <OneLineForm
      appearance="button-icon"
      itemProp="potentialAction"
      itemScope
      itemType="http://schema.org/SearchAction"
      onChange={onChange}
      input={
        <>
          <meta itemProp="target" content={searchTarget} />

          <SearchInput
            name="search"
            id="search"
            value={search?.search}
            onFocus={onFocus}
            isExpanded={isExpanded}
            selected={selected}
            autoFocus
          />
        </>
      }
      lefButton={
        <div className="search-bar__close">
          <IconButton
            appearance="block-primary"
            onClick={() => {
              closeSearch();
            }}
            size="small"
            icon="left-arrow"
            title={intl.formatMessage(messages.search)}
          />
        </div>
      }
      button={
        <>
          {search?.search?.length > 0 ? (
            <div className="search-bar__clear">
              <IconButton
                appearance="block-primary"
                onClick={() => {
                  setSearch({
                    ...search,
                    search: "",
                    from: undefined,
                    showMore: false,
                    canUpdate: false,
                    sort: undefined,
                  });
                }}
                icon="close"
                size="small"
                title=""
              />
            </div>
          ) : (
            <div className="search-bar__search">
              <IconButton
                appearance="block-primary"
                dangerouslyIgnoreOnClick
                size="small"
                icon="magnifying-glass"
                title=""
              />
            </div>
          )}
        </>
      }
    />
  );
};

export default SearchBarForm;
