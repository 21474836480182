import React from "react";
import { defineMessages, useIntl } from "react-intl";

import Text from "theme/components/atoms/Form/Input/Text";
import { useAutocompleteInput } from "theme/components/organisms/Autocomplete/useAutocomplete";

const labels = defineMessages({
  search: {
    id: "modules.Search.SearchBar.SearchInput.placeholder",
    defaultMessage: "what are you looking for ?",
  },
});

const SearchInput = ({
  name,
  value,
  id,
  onFocus,
  isExpanded,
  selected,
  ...rest
}) => {
  const intl = useIntl();
  const autocompleteProps = useAutocompleteInput({ isExpanded, selected });
  return (
    <Text
      {...rest}
      {...autocompleteProps}
      appearance="invisible"
      itemProp="query-input"
      name={name}
      value={value}
      id={id}
      placeholder={intl.formatMessage(labels.search)}
      onFocus={onFocus}
      aria-label={intl.formatMessage(labels.search)}
    />
  );
};

export default SearchInput;
