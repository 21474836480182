import React from "react";
import SearchBar from "theme/modules/Search/SearchBar";

const SearchNavigation = ({ closeSearch, algoliaConfig }) => {
  return (
    <div className="container">
      <SearchBar closeSearch={closeSearch} algoliaConfig={algoliaConfig} />
    </div>
  );
};

export default SearchNavigation;
