import React, { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router";

const useSubNavigation = () => {
  const subNavRef = useRef();

  const [selectedMenu, setSelectedMenu] = useState(null);
  const selectMenu = (id) => {
    setSelectedMenu(id);
    document.body.classList.remove("menu-opened");
  };

  const renderSubNav = (id) =>
    selectedMenu === id
      ? (element) => createPortal(element, subNavRef.current)
      : (element) => <div style={{ display: "none" }}>{element}</div>

  const location = useLocation();
  useEffect(() => {
    selectMenu(null);
  }, [location.key]);

  return [subNavRef, selectMenu, renderSubNav, selectedMenu];
};

export default useSubNavigation;
