import React from "react";
import PropTypes from "prop-types";
import Link from "theme/components/atoms/Typography/Link";
import ListItem from "./ListItem";
import classNames from "classnames";
import H4 from "theme/components/atoms/Typography/Heading/H4";

const ITEM_PER_COLS = 8;

const ListDetail = ({ list, cols }) => (
  <ul
    className={classNames("link-list__list", {
      [`link-list__list--cols-${cols}`]: list.length > ITEM_PER_COLS && cols,
    })}
  >
    {list &&
      list.map((element, index) => {
        if (!element) {
          return null;
        }
        if (!element.path) {
          return (
            <ListItem key={index}>
              {element}
              {element.renderSubNav ? element.renderSubNav() : null}
            </ListItem>
          );
        } else {
          const path = element.path || "/";
          return (
            <ListItem key={index}>
              <Link clickType="block" key={index} to={path}>
                {element.name}
              </Link>
              {element.renderSubNav ? element.renderSubNav() : null}
            </ListItem>
          );
        }
      })}
  </ul>
);

const LinkList = (props) => {
  const listDetail = props.list ? <ListDetail {...props} /> : null;
  return (
    <div className="link-list" style={{ display: props.hidden ? "none" : "" }}>
      <span className="link-list__title h4">{props.title}</span>
      {listDetail}
      {props.extraItems}
    </div>
  );
};

LinkList.propTypes = {
  list: PropTypes.array,
  hidden: PropTypes.bool,
};
export default LinkList;
