import { useState, useEffect } from "react";

const useSearchList = ({ data, search }) => {
  const [products, setProducts] = useState({
    products: [],
  });

  useEffect(() => {
    if (!data?.loading && search.canUpdate) {
      setProducts((previousProducts) => {
        const newProductsState = search.showMore
          ? [...previousProducts.products, ...data?.searchSuggestions?.products]
          : data?.searchSuggestions?.products;
        return {
          products: search.search !== "" ? newProductsState : [],
          query: data?.searchSuggestions?.query || 0,
          total: data?.searchSuggestions?.total || 0,
        };
      });
    }
  }, [
    search,
    data?.searchSuggestions?.products,
    data?.searchSuggestions?.total,
    data?.searchSuggestions?.query,
    data?.loading,
  ]);

  return {
    products,
    setProducts,
  };
};

export default useSearchList;
