import React from "react";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";
import TopNotice from "./TopNotice";

const TopNoticeWithMediaQuery = (props) => (
  <MediaQuery maxWidth={Breakpoints.desktop - 1}>
    {(match) => <TopNotice mobileView={match} enablePaddingBottom {...props} />}
  </MediaQuery>
);

export default TopNoticeWithMediaQuery;
