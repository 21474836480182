import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Wysiwyg from "theme/modules/WysiwygV2";
import EnhanceTopNotice from "./EnhanceTopNotice";
import TopNoticeQuery from "./TopNoticeQuery.gql";
import { compose, branch } from "recompose";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);


const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : () => {};

const TopNotice = ({
  css,
  topNotice,
  mobileView,
  enablePaddingBottom,
  onTopNoticeExist,
}) => {
  const [canDisplay, setCanDisplay] = useState(false);
  const topNoticeRef = useRef();
  const timeoutRef = useRef();

  const initMobileParams = useCallback(() => {
    onTopNoticeExist(mobileView);
  }, [mobileView, onTopNoticeExist]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setCanDisplay(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (topNoticeRef?.current && mobileView && enablePaddingBottom) {
      initMobileParams();
      let topNoticeHeight = topNoticeRef?.current.clientHeight;
      body.style.setProperty('--top-notice-height', topNoticeHeight + "px");
    }
  }, [
    topNoticeRef.current?.clientWidth,
    mobileView,
    enablePaddingBottom,
    initMobileParams,
    canDisplay,
  ]);

  const noticeContent = useMemo(() => {
    return [...Array(3)].map((_, i) => (
      <SwiperSlide key={i}>
        <Wysiwyg content={topNotice.contentWysiwyg} />
      </SwiperSlide>
    ));
  }, [topNotice.contentWysiwyg]);

  if (!canDisplay) {
    return null;
  }

  return (
    <div
      ref={topNoticeRef}
      className={classNames("kap-top-notice", {
        "kap-top-notice-desktop": !mobileView,
        "kap-top-notice-mobile": mobileView,
      })}
      style={css?.value}
    >
      <Swiper
        slidesPerView={"auto"}
        loopedSlides={3}
        loopAdditionalSlides={3}
        centeredSlides
        loop
        speed={8000}
        autoplay={{
          delay: 10,
        }}
        observer
        observeParents
      >
        {noticeContent}
      </Swiper>
    </div>
  );
};

export default compose(
  EnhanceTopNotice({ TopNoticeQuery }),
  branch(
    ({ loading, topNotice }) => loading || !topNotice?.contentWysiwyg,
    () => () => null,
    (BaseComponent) => BaseComponent
  )
)(TopNotice);
