import React from "react";
import Wysiwyg from "theme/modules/WysiwygV2/Wysiwyg";

const CategoriesMenuWidget = ({ category }) => {
  if (!category.kpl_navigation_preview?.childNodes?.length) {
    return null;
  }
  return (
    <Wysiwyg
      content={category.kpl_navigation_preview}
      containerClass="categories-menu--container--wysiwyg"
    />
  );
};

export default CategoriesMenuWidget;
