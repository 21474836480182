import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import Form from "theme/components/atoms/Form/Form";
import FormItem from "theme/components/molecules/Form/Item";
import { SearchSortSelect } from "theme/components/atoms/Form/Input";

const messages = defineMessages({
  sortTitle: {
    id: "modules.Layer.SearchSort.sortLabel",
    defaultMessage: "Sort by",
  },
});

const SearchSort = (props) => {
  return (
    <Form onChange={props.onChange}>
      <FormItem
        label={props.intl.formatMessage(messages.sortTitle)}
        name="sort"
      >
        <SearchSortSelect
          name="sort"
          id="sort"
          placeholder="Pertinence"
          algoliaSortingIndices={props.algoliaSortingIndices}
          value={(props.initialValue && props.initialValue.sort) || undefined}
        />
      </FormItem>
    </Form>
  );
};

export default injectIntl(SearchSort);
