import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import Icon from "theme/components/atoms/Icon";
import MobileMenuList from "./MobileMenuList";
import Link from "theme/components/atoms/Typography/Link";
import StoreViewSelector from "theme/modules/User/StoreViewSelector/StoreViewSelector";

const messages = defineMessages({
  login: {
    id: "layouts.Header.Navigation.Menu.MenuNavigationMobile.login",
    defaultMessage: "Login",
  },
});

const MenuNavigation = ({
  data,
  user,
  closeMenu,
}) => {
  const isLoggedIn = (user?.id ?? null) !== null

  return (
    <nav className="menu-nav-mobile">
      <div className="header__list">
        <MobileMenuList
          navigationMenu={data.navigationMenu}
          closeMenu={closeMenu}
        />
      </div>
      <div className="header__store menu-nav-mobile--bottom-bar">
        <div className="user-info">
          <Icon icon="user" title="user" />
          <div className="user-info--details">
            <Link className={"link__user"}
                  to={!isLoggedIn ? "/login" : "/user"}
            >
              {
                !isLoggedIn
                  ?
                  <FormattedMessage
                    {...messages.login}
                  >{text => <span>{text}</span>}</FormattedMessage>
                  :
                  <span>{user.firstname}</span>
              }
            </Link>
          </div>
        </div>
        <StoreViewSelector openDirection={"right"} />
      </div>
    </nav>
  );
};

export default MenuNavigation;
