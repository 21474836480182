import React from "react";
import classNames from "classnames";
import ProductsResults from "../ProductsResults";
import Stack from "theme/components/atoms/Layout/Stack";
import { FormattedMessage } from "react-intl";
import { useAutocompleteOption } from "theme/components/organisms/Autocomplete/useAutocomplete";
import Button from "theme/components/atoms/Button";

const SearchBarResults = (props) => {
  const [allAutocompleteProps] = useAutocompleteOption(
    "all",
    props.searchUrl,
    props.selected,
    props.onSelect
  );

  const hasProducts = props.results?.products?.length > 0;

  return (
    <div className={classNames("searchbar-results")}>
      <Stack size="2">
        <div className="searchbar-results__results">
          {hasProducts && (
            <div key="products" className="searchbar-results__element">
              <ProductsResults
                results={props.results.products}
                total={props.results.total}
                query={props.results.query}
                selected={props.selected}
                onSelect={props.onSelect}
                algoliaSortingIndices={props.algoliaSortingIndices}
                setSearch={props.setSearch}
                search={props.search}
                onChange={props.onChange}
                loading={props.loading ?? false}
              />
            </div>
          )}
          {hasProducts && props.results.products.length < props.results.total && (
            <div className="searchbar-results__show-more">
              <span {...allAutocompleteProps}>
                <Button
                  state={props.search?.canUpdate ? "pending" : undefined}
                  onClick={() =>
                    props.onLoadMore({
                      ...props.search,
                      from: props.search.from + props.hitsPerPage,
                      showMore: true,
                      canUpdate: true,
                    })
                  }
                  appearance="primary"
                >
                  <span>
                    <FormattedMessage
                      id="modules.Search.SearchBar.SearchBarResults.viewAll"
                      defaultMessage="See all results"
                    />
                  </span>
                </Button>
              </span>
            </div>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default SearchBarResults;
