import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Form from "theme/components/atoms/Form/Form";
import { defineMessages, useIntl } from "react-intl";
import Spinner from "theme/components/atoms/Spinner";
import IconButton from "theme/components/atoms/Button/IconButton";
import NumberInput from "theme/components/atoms/Form/Input/NumberInput";
import FormItem from "theme/components/molecules/Form/Item";
import Select from "theme/components/atoms/Form/Input/Select";

const messages = defineMessages({
  refresh: {
    id: "modules.Cart.CartItem.CartItemQuantityForm.refresh",
    defaultMessage: "Refresh the product quantity",
  },
  quantityLabel: {
    id: "modules.Cart.CartItem.CartItemQuantityForm.quantityLabel",
    defaultMessage: "Quantity",
  },
});

const CartItemQuantityForm = (props) => {
  const intl = useIntl();

  const childOptions = () => {
    let tab = [];
    const maxQty = props.qtyProducts > 9 ? 9 : props.qtyProducts;
    for (let i = 1; i <= maxQty; i++) {
      tab.push({ value: i, label: i.toString() });
    }
    if(props.quantity > props.qtyProducts) {
      tab.push({ value: props.quantity, label: props.quantity.toString() });
    }
    return tab;
  };

  const numberInput = (
    <NumberInput
      name={"quantity"}
      id={"quantity_" + props.item_id}
      value={props.quantity}
      disabled={props.disabled || props.refreshQuantityPending}
      aria-label={intl.formatMessage(messages.quantityLabel)}
      appearance={props.appearance}
    />
  );

  return (
    <Form onChange={props.onRefreshQuantity}>
      <div
        className={classNames("cart-quantity-form", {
          [`cart-quantity-form--${props.appearance}`]: props.appearance,
        })}
      >
        {props.refreshQuantityPending ? (
          <Spinner />
        ) : (
          <Fragment>
            {props.appearance === "small" ? (
              <FormItem
                appearance="inline"
                label={intl.formatMessage(messages.quantityLabel)}
              >
                {numberInput}
              </FormItem>
            ) : (
              <>
                {props.qtyProducts === 0 ? (
                  <div className="no-products">0</div>
                ) : (
                  <Select
                    name="quantity"
                    id="quantity"
                    value={props.quantity}
                    options={childOptions()}
                    disabled={props.disabled || props.refreshQuantityPending}
                  />
                )}
              </>
            )}
            <div className="cart-quantity-form__button">
              <IconButton
                icon="sync"
                title={intl.formatMessage(messages.refresh)}
                type="submit"
                state={
                  props.refreshQuantityPending
                    ? "pending"
                    : props.disabled
                    ? "disabled"
                    : undefined
                }
                onDisableClick={() => {}}
              />
            </div>
          </Fragment>
        )}
      </div>
    </Form>
  );
};
CartItemQuantityForm.propTypes = {
  item_id: PropTypes.oneOfType([PropTypes.string.isRequired]),
  quantity: PropTypes.number.isRequired,
  appearance: PropTypes.oneOf(["default", "small"]),
};

export default CartItemQuantityForm;
