import { compose, withState } from "recompose";
import { graphql } from "react-apollo";

const homogeneizeSuggestions = (data) => {
  const searchSuggestions = data.searchSuggestions && {
    ...data.searchSuggestions,
    products: data.searchSuggestions.products.products,
    total: data.searchSuggestions.products.total,
  };

  return {
    ...data,
    searchSuggestions,
  };
};

const EnhanceSearchBar = ({ SearchQuery, WysiwygSearchBar }) =>
  compose(
    withState("search", "setSearch", {
      from: 0,
      showMore: false,
      canUpdate: true,
      search: "",
    }),
    graphql(WysiwygSearchBar, {
      name: "me",
    }),
    graphql(SearchQuery, {
      skip: ({ search }) =>
        !search.search ||
        !search.canUpdate ||
        (search.from === 0 && search.showMore),
      options: ({ search, algoliaConfig }) => {
        return {
          variables: {
            query: search.search,
            params: {
              from: search.from || 0,
              size: algoliaConfig.hitsPerPage || 12,
              sort: search.sort || undefined,
            },
          },
        };
      },
      props: ({ data }) => {
        return {
          loading: data.loading,
          error: data.error,
          data: homogeneizeSuggestions(data),
        };
      },
    })
  );

export default EnhanceSearchBar;
