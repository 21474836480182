import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import classNames from "classnames";
import { defineMessages, useIntl } from "react-intl";
import useFocusTrap from "theme/components/helpers/a11y/useFocusTrap";
import useKeyDownEvent from "theme/components/helpers/a11y/useKeyDownEvent";
import useBodyFixed from "theme/components/organisms/Modal/useBodyFixed";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : () => {};

const messages = defineMessages({
  close: {
    id: "layouts.Header.SubNavigation.close",
    defaultMessage: "Close",
  },
});

const SubNavigation = ({
  appearance = "default",
  children,
  onClose,
  onEnter,
  selectedMenu,
  active,
}) => {
  const intl = useIntl();

  const contentRef = useRef();
  const endTrap = useFocusTrap(contentRef, 300);

  const [entered, setEntered] = useState(selectedMenu);
  const [opacity, setOpacity] = useState(false);
  useEffect(() => {
    setEntered(true);
    setOpacity(true);
  }, []);

  const onCloseHandler = useCallback(
    (event) => {
      event.preventDefault();
      onClose();
      endTrap();
    },
    [onClose, endTrap]
  );

  useKeyDownEvent(
    contentRef,
    useCallback(
      (event) => {
        // if escape key is pressed within contentRef
        if (event.keyCode === 27) {
          onCloseHandler(event);
        }
      },
      [onCloseHandler]
    )
  );

  useBodyFixed(entered && appearance === "aside");

  useIsomorphicLayoutEffect(() => {
    if (appearance === "aside" && contentRef.current) {
      const box = contentRef.current.getBoundingClientRect();
      contentRef.current.style.height = `${Math.ceil(
        window.innerHeight - box.top
      )}px`;
    }
  }, [appearance]);

  return (
    <div
      className={classNames("navigation__sub", {
        "navigation__sub--opened": active && entered,
        "navigation__sub--opacity": active && opacity,
        [`navigation__sub--${appearance}`]: appearance,
      })}
    >
      <div className="navigation__sub__backdrop" onClick={onCloseHandler}>
        {/* not a button because there's already the button inside the content */}
      </div>
      <div
        tabIndex="-1"
        ref={contentRef}
        className="navigation__sub__content"
        onMouseEnter={appearance !== "menu" ? onEnter : null}
        onMouseLeave={appearance !== "menu" ? onCloseHandler : null}
      >
        <button className="sr-only sr-focusable" onClick={onCloseHandler}>
          {intl.formatMessage(messages.close)}
        </button>
        {children}
      </div>
    </div>
  );
};

export default SubNavigation;
