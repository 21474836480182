import React from "react";
import AutocompleteItem from "theme/modules/ProductView/ProductItem/ProductItem";

const ProductItem = ({ result, index }) => {
  return (
    <AutocompleteItem
      key={result.sku}
      product={result}
      {...result}
      index={index}
      className={"search-result"}
      enableBadges
    />
  );
};

export default ProductItem;
